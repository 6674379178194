<template>
  <v-container>
    <PageHeader
      :pageTitle="pageTitle"
      :pageDescription="$t('pageDescriptions.am')"
    ></PageHeader>

    <v-card dark class="red" v-if="config.inTermination === '1'" elevation="2">
      <v-card-title>Der Vertrag wurde bereits gekündigt.</v-card-title>
      <v-card-text class="white--text">
        <strong
          >Der Vertrag wurde zum {{ whatTerminationDate() }} gekündigt.</strong
        >
        Es tut uns leid dich gehen zu sehen!<br />Bis zum Ende der Laufzeit
        kannst Du deine Planung noch fortführen.<br />Solltest Du Deine Meinung
        ändern, kontaktiere uns bitte.
      </v-card-text>
    </v-card>

    <div style="width: 100%; overflow: auto">
      <v-row no-gutters style="min-width: 800px">
        <v-col cols="4">
          <table class="jmp-table">
            <thead>
              <tr class="primary white--text">
                <th style="width: 30px" class="white--text"></th>
                <th style="width: 130px" class="white--text">
                  {{ $t("month") }} / {{ $t("year") }}
                </th>
                <th style="max-width: 120px" class="white--text">
                  {{ $t("startDate") }}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(item, index) in staticSection"
                :key="index"
                :class="typeClass(item.type)"
              >
                <template v-if="item.type === 'month'">
                  <td
                    v-if="
                      dragSection[index].isLocked === '1' ||
                      dragSection[index].isDisabled === '1'
                    "
                    style="width: 30px"
                  >
                    <v-icon color="red" small>mdi-lock</v-icon>
                  </td>

                  <td v-else style="width: 30px">
                    <v-icon color="grey lighten-2" small>mdi-lock-open</v-icon>
                  </td>

                  <td class="caption font-weight-bold" style="width: 130px">
                    {{ whatMonth(item.month) }} {{ item.year }}
                  </td>

                  <td
                    style="max-width: 120px"
                    v-if="dragSection[index].status !== 'blank'"
                  >
                    <v-select
                      v-model="item.date"
                      :items="item.dateOptions"
                      label="Sonntag"
                      solo
                      hide-details
                      dense
                      :disabled="
                        isDisabled(
                          dragSection[index].isLocked,
                          dragSection[index].isDisabled
                        )
                      "
                      @change="putAction()"
                    ></v-select>
                  </td>

                  <td v-else></td>
                </template>

                <template
                  v-else-if="
                    item.type === 'video' &&
                    dragSection[index].status !== 'blank'
                  "
                >
                  <td
                    v-if="
                      dragSection[index].isLocked === '1' ||
                      dragSection[index].isDisabled === '1'
                    "
                    style="width: 30px"
                  >
                    <v-icon color="red" small>mdi-lock</v-icon>
                  </td>
                  <td v-else style="width: 30px">
                    <v-icon color="grey lighten-2" small>mdi-lock-open</v-icon>
                  </td>

                  <td class="caption font-weight-bold" colspan="2">
                    {{ $t("quarterVideo") }}
                  </td>
                </template>
              </tr>
            </tbody>
          </table>
        </v-col>

        <v-col cols="8">
          <table class="jmp-table">
            <thead>
              <tr class="primary white--text">
                <th style="max-width: 300px" class="white--text">
                  {{ $t("campaign") }} / Video
                </th>
                <th style="width: 40px" class="white--text">
                  {{ $t("preview") }}
                </th>
                <th style="max-width: 250px" class="white--text">Budget</th>
                <th style="width: 40px" class="white--text">
                  {{ $t("messages") }}
                </th>
                <th style="width: 40px" class="white--text"></th>
              </tr>
            </thead>

            <draggable
              v-model="dragSection"
              tag="tbody"
              :move="handleMove"
              @end="handleDragEnd"
              draggable=".is-draggable"
              handle=".draghandle"
            >
              <tr
                v-for="(item, index) in dragSection"
                :key="index"
                :class="
                  draggableClass(item.isLocked) + ' ' + typeClass(item.type)
                "
              >
                <template v-if="item.type === 'month'">
                  <template v-if="item.status === 'available'">
                    <td style="max-width: 300px">
                      <v-select
                        v-model="item.campaigns_id"
                        :items="campaigns"
                        label="Kampagne"
                        item-text="name"
                        item-value="id"
                        solo
                        hide-details
                        dense
                        :disabled="
                          isDisabled(
                            dragSection[index].isLocked,
                            dragSection[index].isDisabled
                          )
                        "
                        @change="putAction()"
                      ></v-select>
                    </td>

                    <td style="width: 40px">
                      <v-btn
                        v-if="previewUrl(item)"
                        fab
                        dark
                        x-small
                        color="blue"
                        elevation="0"
                        :href="previewUrl(item)"
                        target="_blank"
                      >
                        <v-icon dark>mdi-eye</v-icon>
                      </v-btn>
                      <v-btn v-else fab x-small elevation="0" disabled>
                        <v-icon dark>mdi-eye-off</v-icon>
                      </v-btn>
                    </td>

                    <td style="max-width: 250px">
                      <v-select
                        v-model="item.budget"
                        :items="budgets"
                        label="Budget"
                        solo
                        hide-details
                        dense
                        :disabled="
                          isDisabled(
                            dragSection[index].isLocked,
                            dragSection[index].isDisabled
                          )
                        "
                        @change="putAction()"
                      ></v-select>
                    </td>

                    <td style="width: 40px">
                      <SlotChat
                        v-if="!fetchinData"
                        :planId="planId"
                        :slotNo="staticSection[index].id"
                        :key="chatComponentKey"
                      />

                      <v-btn
                        v-else
                        class="mx-2"
                        fab
                        dark
                        x-small
                        color="green"
                        elevation="0"
                      >
                        <v-icon dark>mdi-chat-processing-outline</v-icon>
                      </v-btn>
                    </td>

                    <td
                      v-if="item.isLocked !== '1' || item.isDisabled === '1'"
                      class="draghandle"
                      style="width: 40px"
                    >
                      <v-icon>mdi-drag</v-icon>
                    </td>
                    <td v-else></td>
                  </template>

                  <template v-else>
                    <td colspan="4" class="text-center"></td>
                    <td class="draghandle" style="width: 40px">
                      <v-icon>mdi-drag</v-icon>
                    </td>
                  </template>
                </template>

                <template
                  v-else-if="
                    item.type === 'video' &&
                    dragSection[index].status !== 'blank'
                  "
                >
                  <template v-if="item.status === 'available'">
                    <td style="max-width: 300px">
                      <v-select
                        v-model="item.video"
                        :items="videos"
                        item-text="name"
                        item-value="id"
                        label="Video"
                        solo
                        hide-details
                        dense
                        :disabled="
                          isDisabled(
                            dragSection[index].isLocked,
                            dragSection[index].isDisabled
                          )
                        "
                        @change="putAction()"
                      ></v-select>
                    </td>

                    <td style="width: 40px">
                      <v-btn
                        v-if="previewUrl(item)"
                        fab
                        dark
                        x-small
                        color="blue"
                        elevation="0"
                        :href="previewUrl(item)"
                        target="_blank"
                      >
                        <v-icon dark>mdi-eye</v-icon>
                      </v-btn>
                      <v-btn v-else fab x-small elevation="0" disabled>
                        <v-icon dark>mdi-eye-off</v-icon>
                      </v-btn>
                    </td>

                    <td style="max-width: 250px">
                      <v-select
                        v-model="item.branding"
                        :items="brandings"
                        item-text="name"
                        item-value="value"
                        label="Branding"
                        solo
                        hide-details
                        dense
                        :disabled="
                          isDisabled(
                            dragSection[index].isLocked,
                            dragSection[index].isDisabled
                          )
                        "
                        @change="putAction()"
                      ></v-select>
                    </td>

                    <td style="width: 40px"></td>
                    <td style="width: 40px"></td>
                  </template>

                  <template v-else>
                    <td colspan="3" class="text-center"></td>
                  </template>
                </template>
              </tr>
            </draggable>
          </table>
        </v-col>
      </v-row>
    </div>
    <PlanHelpCard />
  </v-container>
</template>

<script>
import store from "@/store";
import manageApi from "@/mixins/manageApi"; // import mixin (for api requests)
import PageHeader from "@/components/ui/PageHeader";
import PlanHelpCard from "@/components/addons/annual-marketing/PlanHelpCard";
import draggable from "vuedraggable";
import SlotChat from "@/components/addons/annual-marketing/SlotChat";

export default {
  name: "Plan",

  components: {
    draggable,
    PageHeader,
    PlanHelpCard,
    SlotChat,
  },

  data() {
    return {
      chatComponentKey: 0,
      pageTitle: "Jahresmarketing",
      pageDescription:
        "Pellentesque in ipsum id orci porta dapibus. Cras ultricies ligula sed magna dictum porta.",
      fetchinData: false, // should be true when fetching data
      companyID: store.getters["auth/current_sid"],
      planId: null,
      chatDialog: false,
      chatId: null,
      branches_id: null,
      title: null,
      config: [],
      videos: [],
      campaigns: [],
      budgets: [
        "200.00",
        "300.00",
        "400.00",
        "500.00",
        "600.00",
        "700.00",
        "800.00",
        "900.00",
        "1000.00",
        "1100.00",
        "1200.00",
      ],
      dragSection: [],
      staticSection: [],
      brandings: [
        {
          name: "Premium Branding (+400,– €)",
          value: "1",
        },
        {
          name: "Video liegt bereits vor",
          value: "2",
        },
      ],
      futureItem: null,
      movingItem: null,
      futureIndex: null,
      movingIndex: null,
      targetIndex: null,
      sourceIndex: null,
      targetId: null,
      sourceId: null,

      currentTimestamp: Math.floor(Date.now() / 1000),
    };
  },

  props: [],

  created: function () {
    // allow to change company?
    this.$store.commit("companyChangeOption/SET_ALLOWED", false);
    this.getData();
  },

  methods: {
    whatTerminationDate() {
      let terminationDate = this.config.terminationDate;

      if (terminationDate) {
        let date = new Date(terminationDate);
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        return `${day}.${month}.${year}`;
      } else {
        return "";
      }
    },

    previewUrl(item) {
      const id = item.campaigns_id;
      const videoId = item.video;

      let url = null;

      this.campaigns.forEach((el) => {
        if (el.id === id) {
          url = el.previewUrl;
        }
      });

      this.videos.forEach((el) => {
        if (el.id === videoId) {
          url = el.previewUrl;
        }
      });

      return url;
    },

    async putAction() {
      /* 
       this.futureItem = this.dragSection[this.futureIndex];
      this.movingItem = this.dragSection[this.movingIndex]; */

      let dataArr = new URLSearchParams();
      dataArr.append("dragSection", JSON.stringify(this.dragSection));
      dataArr.append("staticSection", JSON.stringify(this.staticSection));

      dataArr.append("targetIndex", JSON.stringify(this.targetIndex));
      dataArr.append("sourceIndex", JSON.stringify(this.sourceIndex));

      dataArr.append("targetId", JSON.stringify(this.targetId));
      dataArr.append("sourceId", JSON.stringify(this.sourceId));

      this.progress = true;
      const response = await this.putRequest(
        "plans/" + this.$route.params.id,
        dataArr
      );
      this.progress = false;

      if (response.status === 200) {
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = this.$t("messageUpdated");
        this.$root.snackbarGlobal.errorMessage = "";
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;

        // mutate
        this.dragSection = response.data.data.dragSection;
        this.staticSection = response.data.data.staticSection;

        if (this.targetId) {
          // targetId is set only when a swap was made
          this.chatComponentKey += 1; // re-render the chat component
        }
      } else {
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = this.$t("messageError");
        this.$root.snackbarGlobal.errorMessage = "";
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
        this.getData(); // refetch to get the real state
      }

      // reset
      this.targetIndex = null;
      this.sourceIndex = null;
      this.targetId = null;
      this.sourceId = null;
    },

    async getData() {
      this.fetchinData = true;
      let response = await this.getRequest(
        "companies/" +
          this.companyID +
          "/addons/annualMarketing/plans/" +
          this.$route.params.id
      ); // await data from mixin

      if (response.status === 200) {
        if (this.companyID === response.data.data.companies_id) {
          // Dirty Security Hack: show plan only, when current company === plan owner
          // todo: Das muss natürlich serverseitig passieren. ISSUE https://github.com/Toge360/mitfit-api/issues/38
          this.planId = response.data.data.id;
          this.dragSection = response.data.data.dragSection;
          this.staticSection = response.data.data.staticSection;
          this.title = response.data.data.title;
          this.branches_id = response.data.data.branches_id;
          this.pageTitle =
            "Jahresmarketing " + this.getSeason(response.data.data.startYear);
          this.config = response.data.data.config;
        }
      }

      //public/products Filter: ?filter[branchIds]=1,3&filter[typeId]=2&filter[categoryIds]=1,2&filter[search]=Suchbegriff
      let productsResponse = await this.getRequest(
        "public/products?filter[branchId]=" +
          this.branches_id +
          "&filter[typeId]=1&filter[isJmp]=1"
      );
      if (productsResponse) {
        /* Hier muss ich einen neuen Array generieren lassen,
        da die Produkte noch mal in einem Object liegen 1: {...}, 2: {...} */
        const that = this;
        Object.keys(productsResponse.data.data).forEach(function (k) {
          that.campaigns.push(productsResponse.data.data[k]);
        });
      }

      let videosResponse = await this.getRequest(
        "public/products?filter[branchId]=" +
          this.branches_id +
          "&filter[typeId]=2&filter[isJmp]=1"
      );
      if (videosResponse) {
        /* Hier muss ich einen neuen Array generieren lassen,
        da die Produkte noch mal in einem Object liegen 1: {...}, 2: {...} */
        const that = this;
        Object.keys(videosResponse.data.data).forEach(function (k) {
          that.videos.push(videosResponse.data.data[k]);
        });
      }

      this.fetchinData = false;
    },

    getSeason(startYear) {
      return startYear + "/" + (parseInt(startYear) + 1);
    },

    whatMonth(num) {
      switch (num) {
        case "1":
          return this.$t("january");
        case "2":
          return this.$t("february");
        case "3":
          return this.$t("march");
        case "4":
          return this.$t("april");
        case "5":
          return this.$t("may");
        case "6":
          return this.$t("june");
        case "7":
          return this.$t("july");
        case "8":
          return this.$t("august");
        case "9":
          return this.$t("september");
        case "10":
          return this.$t("october");
        case "11":
          return this.$t("november");
        case "12":
          return this.$t("december");
      }
    },

    isDisabled(state, disabled) {
      if (state === "0" && (disabled === "0" || !disabled)) {
        return false;
      } else {
        return true;
      }
    },

    draggableClass(state) {
      if (state === "0") {
        return "is-draggable";
      } else {
        return "is-locked";
      }
    },

    typeClass(state) {
      if (state === "month") {
        return "type-month";
      } else if (state === "blank") {
        return "type-blank";
      } else if (state === "video") {
        return "type-video";
      }
    },

    handleDragEnd() {
      /* SWAP-Hack: https://fir-vue-draggable.firebaseapp.com/swap/code */

      this.futureItem = this.dragSection[this.futureIndex];
      this.movingItem = this.dragSection[this.movingIndex];

      this.targetIndex = this.futureIndex;
      this.sourceIndex = this.movingIndex;

      this.targetId = this.staticSection[this.futureIndex].id;
      this.sourceId = this.staticSection[this.movingIndex].id;

      if (
        this.futureItem &&
        this.futureItem !== this.movingItem &&
        this.futureItem.isLocked !== "1" &&
        this.futureItem.isDisabled !== "1" &&
        this.futureItem.type !== "video"
      ) {
        const _items = Object.assign([], this.dragSection);
        _items[this.futureIndex] = this.movingItem;
        _items[this.movingIndex] = this.futureItem;

        // nullen des dates wenn ein leer monat

        this.dragSection = _items;

        this.futureIndex = null;
        this.movingIndex = null;

        // nullen des dates wenn ein leer monat
        this.dragSection.forEach((item, index) => {
          if (item.type === "month" && item.status === "blank") {
            this.staticSection[index].date = null;

            console.log(this.staticSection);
          }
        });

        this.putAction();
      }
    },

    handleMove(e) {
      /* SWAP-Hack: https://fir-vue-draggable.firebaseapp.com/swap/code */

      this.movingIndex = e.draggedContext.index;
      this.futureIndex = e.draggedContext.futureIndex;
      return false; // disable sort
    },

    addZero(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    },

    formatDate(date) {
      const dt = new Date(date);
      const d = this.addZero(dt.getDate()); //Month from 0 to 11
      const m = this.addZero(dt.getMonth() + 1); //Month from 0 to 11
      const y = dt.getFullYear();
      return d + "." + m + "." + y;
    },
  },

  computed: {
    //
  },

  /* all requests by mixin */
  mixins: [manageApi],
};
</script>

<style>
.draghandle {
  cursor: pointer;
}

.jmp-table .type-blank {
  background-color: white;
}

.jmp-table .type-regular {
  background-color: white;
}

.jmp-table .type-video {
  background-color: #222222 !important;
  color: white;
  min-height: 5px;
}

.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: rgba(0, 0, 0, 0.01);
}
</style>
