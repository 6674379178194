<template>
  <v-container>
    <v-card>
      <v-card-text v-html="$t('pageDescriptions.planHelp')"></v-card-text>
      </v-card>
  </v-container>
</template>

<script>
  export default {

    name: 'PlanHelpCard',

    props: [],

    data () {
      return {
        //
      }
    },

  }
</script>