var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('PageHeader',{attrs:{"pageTitle":_vm.pageTitle,"pageDescription":_vm.$t('pageDescriptions.am')}}),(_vm.config.inTermination === '1')?_c('v-card',{staticClass:"red",attrs:{"dark":"","elevation":"2"}},[_c('v-card-title',[_vm._v("Der Vertrag wurde bereits gekündigt.")]),_c('v-card-text',{staticClass:"white--text"},[_c('strong',[_vm._v("Der Vertrag wurde zum "+_vm._s(_vm.whatTerminationDate())+" gekündigt.")]),_vm._v(" Es tut uns leid dich gehen zu sehen!"),_c('br'),_vm._v("Bis zum Ende der Laufzeit kannst Du deine Planung noch fortführen."),_c('br'),_vm._v("Solltest Du Deine Meinung ändern, kontaktiere uns bitte. ")])],1):_vm._e(),_c('div',{staticStyle:{"width":"100%","overflow":"auto"}},[_c('v-row',{staticStyle:{"min-width":"800px"},attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"4"}},[_c('table',{staticClass:"jmp-table"},[_c('thead',[_c('tr',{staticClass:"primary white--text"},[_c('th',{staticClass:"white--text",staticStyle:{"width":"30px"}}),_c('th',{staticClass:"white--text",staticStyle:{"width":"130px"}},[_vm._v(" "+_vm._s(_vm.$t("month"))+" / "+_vm._s(_vm.$t("year"))+" ")]),_c('th',{staticClass:"white--text",staticStyle:{"max-width":"120px"}},[_vm._v(" "+_vm._s(_vm.$t("startDate"))+" ")])])]),_c('tbody',_vm._l((_vm.staticSection),function(item,index){return _c('tr',{key:index,class:_vm.typeClass(item.type)},[(item.type === 'month')?[(
                    _vm.dragSection[index].isLocked === '1' ||
                    _vm.dragSection[index].isDisabled === '1'
                  )?_c('td',{staticStyle:{"width":"30px"}},[_c('v-icon',{attrs:{"color":"red","small":""}},[_vm._v("mdi-lock")])],1):_c('td',{staticStyle:{"width":"30px"}},[_c('v-icon',{attrs:{"color":"grey lighten-2","small":""}},[_vm._v("mdi-lock-open")])],1),_c('td',{staticClass:"caption font-weight-bold",staticStyle:{"width":"130px"}},[_vm._v(" "+_vm._s(_vm.whatMonth(item.month))+" "+_vm._s(item.year)+" ")]),(_vm.dragSection[index].status !== 'blank')?_c('td',{staticStyle:{"max-width":"120px"}},[_c('v-select',{attrs:{"items":item.dateOptions,"label":"Sonntag","solo":"","hide-details":"","dense":"","disabled":_vm.isDisabled(
                        _vm.dragSection[index].isLocked,
                        _vm.dragSection[index].isDisabled
                      )},on:{"change":function($event){return _vm.putAction()}},model:{value:(item.date),callback:function ($$v) {_vm.$set(item, "date", $$v)},expression:"item.date"}})],1):_c('td')]:(
                  item.type === 'video' &&
                  _vm.dragSection[index].status !== 'blank'
                )?[(
                    _vm.dragSection[index].isLocked === '1' ||
                    _vm.dragSection[index].isDisabled === '1'
                  )?_c('td',{staticStyle:{"width":"30px"}},[_c('v-icon',{attrs:{"color":"red","small":""}},[_vm._v("mdi-lock")])],1):_c('td',{staticStyle:{"width":"30px"}},[_c('v-icon',{attrs:{"color":"grey lighten-2","small":""}},[_vm._v("mdi-lock-open")])],1),_c('td',{staticClass:"caption font-weight-bold",attrs:{"colspan":"2"}},[_vm._v(" "+_vm._s(_vm.$t("quarterVideo"))+" ")])]:_vm._e()],2)}),0)])]),_c('v-col',{attrs:{"cols":"8"}},[_c('table',{staticClass:"jmp-table"},[_c('thead',[_c('tr',{staticClass:"primary white--text"},[_c('th',{staticClass:"white--text",staticStyle:{"max-width":"300px"}},[_vm._v(" "+_vm._s(_vm.$t("campaign"))+" / Video ")]),_c('th',{staticClass:"white--text",staticStyle:{"width":"40px"}},[_vm._v(" "+_vm._s(_vm.$t("preview"))+" ")]),_c('th',{staticClass:"white--text",staticStyle:{"max-width":"250px"}},[_vm._v("Budget")]),_c('th',{staticClass:"white--text",staticStyle:{"width":"40px"}},[_vm._v(" "+_vm._s(_vm.$t("messages"))+" ")]),_c('th',{staticClass:"white--text",staticStyle:{"width":"40px"}})])]),_c('draggable',{attrs:{"tag":"tbody","move":_vm.handleMove,"draggable":".is-draggable","handle":".draghandle"},on:{"end":_vm.handleDragEnd},model:{value:(_vm.dragSection),callback:function ($$v) {_vm.dragSection=$$v},expression:"dragSection"}},_vm._l((_vm.dragSection),function(item,index){return _c('tr',{key:index,class:_vm.draggableClass(item.isLocked) + ' ' + _vm.typeClass(item.type)},[(item.type === 'month')?[(item.status === 'available')?[_c('td',{staticStyle:{"max-width":"300px"}},[_c('v-select',{attrs:{"items":_vm.campaigns,"label":"Kampagne","item-text":"name","item-value":"id","solo":"","hide-details":"","dense":"","disabled":_vm.isDisabled(
                          _vm.dragSection[index].isLocked,
                          _vm.dragSection[index].isDisabled
                        )},on:{"change":function($event){return _vm.putAction()}},model:{value:(item.campaigns_id),callback:function ($$v) {_vm.$set(item, "campaigns_id", $$v)},expression:"item.campaigns_id"}})],1),_c('td',{staticStyle:{"width":"40px"}},[(_vm.previewUrl(item))?_c('v-btn',{attrs:{"fab":"","dark":"","x-small":"","color":"blue","elevation":"0","href":_vm.previewUrl(item),"target":"_blank"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-eye")])],1):_c('v-btn',{attrs:{"fab":"","x-small":"","elevation":"0","disabled":""}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-eye-off")])],1)],1),_c('td',{staticStyle:{"max-width":"250px"}},[_c('v-select',{attrs:{"items":_vm.budgets,"label":"Budget","solo":"","hide-details":"","dense":"","disabled":_vm.isDisabled(
                          _vm.dragSection[index].isLocked,
                          _vm.dragSection[index].isDisabled
                        )},on:{"change":function($event){return _vm.putAction()}},model:{value:(item.budget),callback:function ($$v) {_vm.$set(item, "budget", $$v)},expression:"item.budget"}})],1),_c('td',{staticStyle:{"width":"40px"}},[(!_vm.fetchinData)?_c('SlotChat',{key:_vm.chatComponentKey,attrs:{"planId":_vm.planId,"slotNo":_vm.staticSection[index].id}}):_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","x-small":"","color":"green","elevation":"0"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-chat-processing-outline")])],1)],1),(item.isLocked !== '1' || item.isDisabled === '1')?_c('td',{staticClass:"draghandle",staticStyle:{"width":"40px"}},[_c('v-icon',[_vm._v("mdi-drag")])],1):_c('td')]:[_c('td',{staticClass:"text-center",attrs:{"colspan":"4"}}),_c('td',{staticClass:"draghandle",staticStyle:{"width":"40px"}},[_c('v-icon',[_vm._v("mdi-drag")])],1)]]:(
                  item.type === 'video' &&
                  _vm.dragSection[index].status !== 'blank'
                )?[(item.status === 'available')?[_c('td',{staticStyle:{"max-width":"300px"}},[_c('v-select',{attrs:{"items":_vm.videos,"item-text":"name","item-value":"id","label":"Video","solo":"","hide-details":"","dense":"","disabled":_vm.isDisabled(
                          _vm.dragSection[index].isLocked,
                          _vm.dragSection[index].isDisabled
                        )},on:{"change":function($event){return _vm.putAction()}},model:{value:(item.video),callback:function ($$v) {_vm.$set(item, "video", $$v)},expression:"item.video"}})],1),_c('td',{staticStyle:{"width":"40px"}},[(_vm.previewUrl(item))?_c('v-btn',{attrs:{"fab":"","dark":"","x-small":"","color":"blue","elevation":"0","href":_vm.previewUrl(item),"target":"_blank"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-eye")])],1):_c('v-btn',{attrs:{"fab":"","x-small":"","elevation":"0","disabled":""}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-eye-off")])],1)],1),_c('td',{staticStyle:{"max-width":"250px"}},[_c('v-select',{attrs:{"items":_vm.brandings,"item-text":"name","item-value":"value","label":"Branding","solo":"","hide-details":"","dense":"","disabled":_vm.isDisabled(
                          _vm.dragSection[index].isLocked,
                          _vm.dragSection[index].isDisabled
                        )},on:{"change":function($event){return _vm.putAction()}},model:{value:(item.branding),callback:function ($$v) {_vm.$set(item, "branding", $$v)},expression:"item.branding"}})],1),_c('td',{staticStyle:{"width":"40px"}}),_c('td',{staticStyle:{"width":"40px"}})]:[_c('td',{staticClass:"text-center",attrs:{"colspan":"3"}})]]:_vm._e()],2)}),0)],1)])],1)],1),_c('PlanHelpCard')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }